import React from "react";
import '../../css/games/shared/StatsAndInfo.css';
import LeftIcon from "../../icons/left-icon.png";
import RightIcon from "../../icons/right-icon.png";
import {DisplayStats} from "./Constants";
import { FaRegClock } from "react-icons/fa";
// import { MdIosShare } from "react-icons/md";
// import { IoMdPrint } from "react-icons/io";

export const StatsAndInfo = ({ gameState, onNewGameInCategory }) => {
    return (
        <div className={`stats-and-info-container ${gameState.gameType.cssId}`}>
            {gameState.gameType.displayStats.includes(DisplayStats.CATEGORY) && <CategoryDisplay gameState={gameState} onNewGameInCategory={onNewGameInCategory} />}
            {gameState.gameType.displayStats.includes(DisplayStats.LIVES) && <p>Lives: {gameState.lives}</p>}
            {gameState.gameType.displayStats.includes(DisplayStats.WORDS_LEFT) && <p>Words left: {gameState.wordsLeft}</p>}
            {gameState.gameType.displayStats.includes(DisplayStats.TILES_USED) && <p className={'tiles-used'}>Tiles used: {gameState.tilesUsed}</p>}
            {gameState.gameType.displayStats.includes(DisplayStats.TIME) && <TimerView gameState={gameState} />}
            {/*<IoMdPrint className='print-icon' onClick={() => gameState.onShare()}/>*/}
            {/*<MdIosShare className='share-icon' onClick={() => gameState.onShare()}/>*/}
        </div>
    )
}

export const CategoryDisplay = ({ gameState, onNewGameInCategory }) => {
    const isLeftDisabled = gameState.gameNumber === 1;

    return (
        <div className="category-display">
            <img src={LeftIcon} alt="Left Arrow" className={`category-arrow ${isLeftDisabled ? 'disabled-arrow' : ''}`} onClick={() => !isLeftDisabled && onNewGameInCategory(false)}/>
            <h2 className="category-heading">{gameState.category} #{gameState.gameNumber}</h2>
            <img src={RightIcon} alt="Right Arrow" className="category-arrow" onClick={() => onNewGameInCategory(true)}/>
        </div>
    );
};

const TimerView = ({gameState}) => {
    return (
        <div className="game-info-and-icon-container">
            <FaRegClock className="game-info-clock-icon"/>
            <p>{gameState.getDisplayTime()}</p>
        </div>
    )
}

// const GameRating = ({gameState}) => {
//     let ratingString = (gameState.averageRating) ? gameState.averageRating : '?';
//     return (
//         <div className="game-info-and-icon-container">
//             <p>Rating:<sup>{ratingString}</sup>&frasl;<sub>5</sub></p>
//             {/*<FaRegStar/>*/}
//         </div>
//     )
// }
//
// const PuzzlePlayStats = ({gameState, onNewGameInCategory}) => {
//     return (
//         <div className="plays-and-ratings-container">
//             <p className="category-play-count">Plays: {gameState.playCount}</p>
//             <p className="category-rating">Rating: {gameState.averageRating}</p>
//         </div>
//     )
// }